/* 
This only focuses on the Navigation Bar 
*/
/* The header on top of the nav */

.navbar-text-info {
  /* original color: */
  background: #50d39f !important;
  font-family: Arial !important;
  color: black !important;
  text-align: center !important;
  align-content: center !important;
  padding: 15px !important;
  /* font-weight: 400 !important; */
  font-weight: bolder !important;
  margin: 0 !important;
  font-size: 16px !important;
  position: relative;
  z-index: 98;
}
.top-page-nav-text{
  font-weight: bolder !important;

}
.official-nav-logo-text-bar {
  background: #263340;
  white-space: nowrap !important;
}
.official-ky-text {
  color: #ffffff;
  /* padding: 8px 10px 8px 0; */
  display: inline-block;
  height: auto;
  margin-left: 0 !important;
  color: #fff;
  font-family: "Myriad Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;

  font-size: 24px;
}

.slogan-hide {
  color: #fff;
  display: inline-block;
  padding-top: 12px;
  font-size: 11px;
  align-items: center !important;
  text-align: center !important;
  align-content: center !important;

  font-family: "Myriad Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  @media (max-width: 767px) {
    display: none !important;
  }
}

.lower-navs-layout {
  background-color: #ffffff;
}
.dropdown-menu.dropdown-width {
  width: 450px !important;
  position: absolute;
  /* left: -300px; */
  /* border-right-width: -15px; */
}
.middle-nav-formation{
  margin-left: 20px;
  margin-right: 20px;
  padding-right: 20px;
  padding-left: 20px;
}

.flex-vcenter {
  /* align-items: center !important;
  text-align: center !important;
  align-content: center !important; */

  @media only screen and (min-width: 676px) {
    align-items: center !important;
    text-align: center !important;
    align-content: center !important;
  }
}

.middle-nav {
  /* line-height: 1.25; */
  font-size: 0.938rem;

  align-items: center !important;
  text-align: center !important;
  align-content: center !important;
}
.google-widget-translate {
  /* margin-left: 132%; */
  font-size: 9px;
  margin-right:0px;


  @media only screen and (max-width: 390px) {
    margin-left: 0px !important;
  }
}
.google-widget-translate {
  @media only screen and (max-width: 1399px) {
    
    
  }
}
.google-widget-translate {
  @media only screen and (max-width: 1199px) {
    margin-left: 26%;
  }
}
.google-widget-translate {
  @media only screen and (max-width: 430px) {
    margin-left: 23px !important;
  }
}
.google-widget-translate {
  @media only screen and (max-width: 768px) {
    margin-left: 9%;
  }
}
.google-widget-translate {
  @media (max-width: 1199px) {
    margin-left: 35%;
    
  }
}
.department-text-logo {
  font-size: 15px;
  /* margin-right: 422px; */
  /* margin-left: -15px; */
  margin-left: 11px;
  

  font-family: "Myriad Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  @media (max-width: 1199px) {
    display: none !important;
    
  }
}
/* .department-text-logo{
  @media (max-width:1399px) {

    margin-right: 699px !important;

    
  }
} */

.line-separation {
  border-bottom: 1px solid #ddd;
  margin: 0px;
  padding: 0px;

  width: auto;
}

.logo-and-search-bar {
  background-color: #ffffff;

  /* padding-left:  100px;
  padding-right: 100px; */
}
.dbhdid-logo {
  /* position:relative ; */

  margin-bottom: -40px;
  margin-top: 25px;
  margin-left: -25px;

  width: 200px;
  height: 100px;

  /* vertical-align: middle; */

  @media only screen and (max-width: 991px) {
    display: inline-block;
    padding: 0px;
    margin-left: 0px;
    margin-right: 20px;
    padding: 0;
    float: none;
    width: 205px;
    height: 130px;
  }
}

.searchbar {
  width: auto;

  margin-left: -429px;
  padding-right:0px;

  margin-bottom: 40px;
  margin-top: -69px;

  @media only screen and (max-width: 991px) {
    margin-left: 0px;
    margin-bottom: 0px;
    margin-top: 75px;
    align-content: center;

    width: auto;
  }
}
.btn-size{
  width: 100px;
  /* padding-right: 0px; */
}

.font-custom {
  @media screen and (min-width: 941px) {
    /* margin: 15px 10px 15px ; */
  }
}

.department-nav {
  color: #ffffff !important;
  /* align-content: center !important;
  align-self: center !important;
  align-items: center; */
  /* position: static ; */

  align-content: center !important;
  text-align: center !important;

 
  
  background: linear-gradient(178deg, #384d60 10%, #263340 100%);
  width: 100% !important;
  
  
  
  /* box-sizing:border-box; */

  position: relative;
  /* box-sizing: content-box; */

  @media only screen and (max-width: 991px) {
    background: none;

    border-top: none;
  }
}
.central-nav-container{
  margin-left: 20px;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: 20px;
  width: auto;

  

  margin: auto;
  
  
}
 .button-nav{
  max-width: 2000px;
}
.container-nav-container{
  @media only screen and (max-width: 2528px) {

    width: 100px;
    margin: auto;
    
    
  }

}


.container-nav-container{
  @media only screen and (max-width: 1199px) {
    
  }

}


.font-custom {
  color: #ffffff !important;

  text-decoration-line: none !important;
  text-align: center!important;
  
 
  text-decoration: none;
  align-items: center !important;
  display: flex;


  margin: 20px 60px 20px;
  max-width: 100%;

  contain: content;

  display: inline-block;

  @media only screen and (max-width: 991px) {
    color: black !important;
  }
}

.font-custom {
  @media only screen and (max-width: 1199px) {
    margin: 20px 20px 20px !important;
  }
}
.font-custom {
  @media only screen and (max-width: 1411px) {
    /* //1411 */
    margin: 20px 58px 20px;
  }
}
.font-custom {
  @media only screen and (max-width: 1440px) {
    /* //1411 */
    margin: 20px 18px 20px;
  }
}
.font-custom {
  @media only screen and (max-width: 1416px) {
    /* //1411 */
    margin: 20px 18px 20px;
  }
}
.font-custom {
  @media only screen and (max-width: 1399px) {
    margin: 20px 44px 20px;
  }
}
.font-custom {
  @media only screen and (max-width: 1397px) {
    margin: 20px 45px 20px;
  }
}
.font-custom {
  @media only screen and (max-width: 1258px) {
    margin: 20px 4px 20px;
  }
}


.font-custom {
  @media only screen and (max-width: 1062px) {
    margin: 20px 1px 20px;
  }
}

.font-custom:hover {
  opacity: 1;
  color: #fff !important;
  font-weight: bold;
  @media only screen and (max-width: 991px) {
    color: black !important;
  }
}
.font-custom::before {
  transition: 300ms;

  height: 3px;

  content: "";
  position: absolute;
  color: white;

  background-color: #5fb3e5;
}

.font-custom::before {
  width: 0%;
  bottom: 5%;
}
.font-custom:hover::before {
  width: 100%;
}

.nav-item {
  margin:auto;
  display: flex;
  padding: auto;
  

  font-weight: 650;
  flex-wrap: nowrap;
  font-size: 19px;
  container:initial;


  @media only screen and (max-width: 991px) {
    border-top: 1px solid #ddd;
  }
}

.nav-layout {
  top: 51px;
  width:auto;
  /* margin-top: 170px; */
  /* margin-left:auto; */
  border-top: solid black 1px;
  justify-content: space-between !important;

  

  @media only screen and (max-width: 991px) {
    border-top: none;
  }
}

.bottom-nav {
  position: relative;
  z-index: 5;
  box-shadow: 1px 3px 12px -1px rgba(0, 0, 0, 0.22);
  margin-bottom: 56px;

  @media only screen and (max-width: 999px) {
    height: 355px;
  }
}
/* For mobile button */

.move-menu-word{
  /* margin-top: 35px; */
  /* text-align: center; */
}

.nav-button-design {
  text-align: center;

  
 
  padding: 21px 10px 20px;
  /* padding-top: 20px; */
  /* padding-bottom: 90px; */
  flex: 0 0 auto;
  color: #fff;
  font-weight: 500;
  background-color: #0065b8;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);

  
}

.layout-crumb {
  background-image: url("../../img/so-white.png");

  margin-left: -350px !important;

  /* border-bottom: 1px solid #ebebeb; */
  width: auto !important;

  padding-bottom: 1px;
  /* margin-bottom: 40px; */
  background-color: transparent;
  /* border-bottom: 1px solid #ebebeb; */

  font-size: 17px;

  @media only screen and (max-width: 1395px) {
    text-align: left;
    margin-top: 0px !important;
    margin-bottom: 20px;
    /* margin-left: 70px !important; */
  }
}
.layout-crumb{
  @media only screen  and (max-width:1992 ) {

    /* margin-left: -50px !important; */
    
  }
}
.full-breadcrumb{
  margin-left: 328px

}

/* breadcrumbs */
.breadcrumbs {
  
  color: #0065b8;
  text-align: left;
  margin-bottom: 25px;
 

  text-decoration: none;
  /* text-decoration: underline !important; */

  /* padding-top: 1.32875rem; */
}
.breadcrumbs > * {
  display: inline-block;

  /* text-decoration: underline !important; */
  text-align: left;
  color: #0065b8;
  font-weight: bolder;
}
.breadcrumbs .crumb:after {
  text-decoration: none !important;
  margin: 4px;
  content: ">";
  color: #0065b8 !important;

  align-content: center;
  /* font-weight: bolder; */
  margin-left: 5px;
}
/* Keeps the last crumb receiving a "<" in front of it. */
breadcrumbs .crumb:last-child:after {
  content: ">";
  margin-left: 10px;
}
.breadcrumbs .crumb:last-child:after {
  display: none;
}
.breadcrumb-border {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 50px;
  margin-top: -10px;
  /* margin-left: 190px !important; */
  margin-right: 190px !important;
  @media only screen and (max-width: 1395px) {
    display: hidden;
  }
}

