/* imports */
/* Font Awesome Icons: */
@import "../SocialIcon/font-awesome/font-awesome-4.7.0/css/font-awesome.css";


/* This CSS file are going to be use generally for all pages.*/
.main-body {
  background-image: url("../../img/so-white.png");
}
.body-can {
  contain: content;

  align-items: center !important;

  border: 3px solid green; /* testing */

  font-size: 25px;
}
.wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  max-width: 2000px;
  /* column-gap: 256px; */
  @media all and (max-width: 600px) {
    /* We tell both sidebars to share a row */
    display:block;
  }
  
  
 
}
.wrapper > * {
  flex: 1 100%;
}

.central-nav-container{

  margin-left: 20px;
  padding-right: 20px;
  padding-left: 20px;
  /* margin-right: 200px; */
  width: auto;

  

  margin: auto;

}

/* Medium screens */
@media all and (min-width: 600px) {
  /* We tell both sidebars to share a row */
  .aside { flex: 1 auto; }
}



@media all and (min-width: 800px) {
  /* We invert order of first sidebar and main
   * And tell the main element to take twice as much width as the other two sidebars 
   */
   /* This will adust the width of the html element:article */
  .main { flex: 49 0px; }
  /* .aside-1 { order: 1; } */
  .main    { order: 2; }
  .aside-2 { order: 3; }
 
}
#root {
  width: 100%;
}
.head3 {
  margin-left: 10px;
  list-style-type: circle;
}
.head4 {
  margin-left: 20px;
  list-style-type: square;
}
.homepage-quick-cta{
  position: sticky !important;
  /* position: fixed; */
  /* min-width: 220px; */
  padding: 12px;
  
  height: unset;
    width: -moz-fit-content;
    width: fit-content;
    /* margin-right: 0.5rem; */
    /* margin-left: auto; */
  
}

.table-of-content {
  position: sticky; /* Make it stick/fixed */
  /* margin-top: 650px; */
  /* padding-bottom:400px; */
  /* width: 340px; */
  
  min-width: 220px;
  padding: 12px;
  height: unset;
    width: -moz-fit-content;
    width: 100%;
    /* margin-right: 0.5rem; */
    /* margin-left: auto; */

  border-left: 1px solid #ddd !important;
  /* border-bottom: 1px dotted #0065b8; */

  top: 0px;
  bottom: 4px;
  max-height: calc(100vh - 70px);
  overflow: auto;
  /* margin-top: 150px; */
}

@media (max-width: 767px) {
  .table-of-content {
    border-left: none !important;
    /* margin-left: 100px; */
    position: fixed;
    top: 400px;
    left: 10px;
  }
}

@media (max-width: 767px) {
  .tof-design {
    top: 0px;
  }
}
@media (max-width: 767px) {
  .tof-card {
    background-color: #ffff;
    border: 1px outset black;
    width: 108%;

    border-radius: 4%;

    color: black;
    list-style-position: inside;
  }
}
/* Error page */
.error {
  align-content: center;
  text-align: center;
  font-weight: bolder;
  margin-bottom: 50px;
}

.slider {
 

  align-content: center;
  
  width: 100%;
  height: 960px;
  object-fit: contain !important;

  position: relative !important;

  align-items: center;

  max-height: 500px;
  padding: 0px 0 0px;
  position: absolute;

}



.hero-header {
  margin: 0;
  color: #fff;
  font-size: 2.513rem;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.85), 2px 2px 12px rgba(0, 0, 0, 0.65);
  text-align: center;
  margin-top: -350px;
  position: relative;
  margin-bottom: 230px;
  z-index: 1;
}

.overlap-box {
  margin-top: -80px;
  padding: 2.92325rem;
  background: #fff;
  margin-bottom: 3.7205rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  position: relative;
  z-index: 1;
}

Home-Image-Top-Box {
  margin-top: 16px !important;
}
.second-box-image {
  margin-bottom: 20px;
}
.card-text-body li {
  margin-bottom: 2px;
}
.values-title {
  margin-left: 25px;
}
.page-image-center {
  max-width: 100%;
  text-align: center !important;
  align-items: center !important;
  align-content: center !important;
  align-self: center;
  overflow: hidden;
}


.Homepage-Layout .hero {
  background-color: #2f4050;
  min-height: 350px;
  margin: -20 -15px -100px;
  position: relative;
  z-index: 1;
}

.body-can-sub {
  text-align: left;
  text-align: left;
  align-items: center !important;
  text-align: left;
  align-content: center !important;
  align-self: center !important;
  justify-content: center !important;
}
.row.body {
  inline-size: auto;
  width: auto;
}

.main-header-layout {
  @media only screen and (min-width: 100px) {
    /*  */
    font-weight: 500 !important;
    margin-bottom: 55px !important;
    width: auto;
    /* margin-right:20px; */
  }
}

.green-underline {
  height: 5px;
  bottom: 0 !important;
  left: 0;
  content: "";
  width: inherit;
  background: #50d39f;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.body-layout-width {
  text-align: left;
  align-self: center;
  align-content: center;
  align-items: center;
}
.Homepage-Layout.hero.wellness {
  background-color: rgba(244, 244, 244, 0.85);
  margin-top: 60px;
}
.search-text {
  color: black;
}
.wellness {
  min-height: 20px;
  padding: 25px;
  padding-right: 10px;
  margin-bottom: 20px;
  /* background-color: #f5f5f5; */
  border: 1px solid #ddd;
  border-radius: 2px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
h2 {
  font-size: 1.6rem;
}
h2 {
  font-weight: 700;
  line-height: 1.31579;
  margin-top: 0px;
  color: black;
  margin-bottom: 1px;
}
.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
}

.mainContentArea.btn-primary {
}
.mainContentArea.btn {
  font-size: 15px;
  font-weight: 700;
  font-style: normal;
  border: 2px solid transparent;
  text-decoration: none;
}

/* Quick Tabs */
.Homepage-Layout.notices {
  /* background: #f5f5f5; */
  padding: 25px 0 35px;
  margin: 0 -15px;
  position: relative;
  z-index: 3;
}
.helper-title {
  margin: 0 0 0 -15px;
  padding: 10px;
  background: #2f4050;
  color: #fff;
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
}
.no-bullet {
  list-style-type: none;
  padding-left: 0;
}
/* Links from homepage*/
.quick-links {
  padding-bottom: 3px;
  padding-top: 3px;
  margin-right: 10px;
  padding-right: 10px;
  display: block;
  font-weight: bold;

  border-bottom: 1px dotted #0065b8;
  color: #0065b8;
  text-decoration: none;
  list-style-type: none;
}
.quick-links-body {
  padding-bottom: 20px;
  padding-top: 3px;
  margin-right: 0px;
  padding-right: 0px;
  display: block;
  font-weight: bold;
  font-size: 20px;

  color: #0065b8;
  text-decoration: none;
  list-style-type: none;
}
/* Page Title, Description */
.sectionTitle {
  font-size: 20px;
  padding-left: 13px;
}
h2.bordered {
  padding-bottom: 10px;
}

h1 {
  font-family: "Roboto", sans-serif;
  margin-left: 12px;
}
h4 {
  margin-left: 15px;
}
/* I wanted to create spacing for list elements */
ul {
  margin-top: 1px;
}
/* Deals with text (Paragraphs, list items)  */
/* Old Attribute name:  */
p.agencyElement-Plead {
  font-family: "Open Sans", sans-serif;
  text-align: left;
  font-size: 500px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.867rem;
  color: black;
  margin: 20px;
  padding: 20px;
}
a {
  overflow-wrap: break-word;
  color: #0065b8;
}
.bodyText p {
  font-family: "Roboto", sans-serif;
  text-align: left;
  font-size: 1.283rem;
  /* font-weight: 800; */
  font-style: normal;
  line-height: 1.867rem;
  margin-right: auto;
  color: #333;
  /* margin: 2px; */
  /* margin-left: 20px; */
  padding: 15px;

  /* padding-left:30px; */
  transition: all 0.15s ease-out;
  /* overflow-wrap: break-word; */
}
/* Sub Header (h2) */
.bodyText h4 {
  font-size: 1.867;
  margin-top: 10px;
  line-height: 1.1111;
}
.text-center {
  margin-top: 0;
  margin-bottom: 50px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  line-height: 1.3;
  color: #333;
  align-content: left;
  text-align: left;
}

.borderblock {
  align-content: left;

  margin-top: 0;
  text-align: left;

  /* margin: auto; */

  /* margin-right:0px; */
}
@media (max-width: 767px) {
  .pageTitle-wrapper {
    margin: 13px;
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .borderblock {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .pageTitle-wrapper {
    margin: 13px;
    margin-bottom: 30px;
  }
}
/* Sub-Titles */
.subTitle {
  text-align: left;
}

/* Contact Information */
/* border */
h2.bordered {
  /* border-bottom: 5px solid #19c07e; */
  padding-top: 10px;
  padding-bottom: 2px;
}
/* Phone/fax Icons */
.fa-ul .fa-li {
  color: #0065b8;
  font-size: 1.233rem;
}

.fa-li {
  /* left: calc(var(--fa-li-width, 2em)*-1); */
  position: absolute;
  /* text-align: center; */
  /* width: var(--fa-li-width,2em); */
  /* line-height: inherit; */
  color: #333;
}
.fa-ul {
  list-style-type: none;
  /* margin-left: var(--fa-li-margin,2.5em); */
  padding-left: 0;
}

.phone {
  color: black;
  font-weight: bold;

  /* padding-top: 20px; */
}
.fax {
  color: black;
  font-weight: bold;
}
.person {
  color: black;
  font-weight: bold;
}

/* Mailing Addresses */
.mail-header {
  margin-top: 20px;
  font-weight: bold;
}
address {
  margin-top: 10px;
  margin-bottom: 30px;
  font-style: normal;
  line-height: 1.42857143;
}
/* Comp: layoutOne */
.info {
  /* position: relative; */
  /* padding-Top: 50px;
  padding-left: 0px; */

  @media (max-width: 767px) {
    width: auto;
  }
}
.up {
  color: #0065b8;
  font-size: 100px;
  margin: 20px;

  align-content: center;
  align-items: center;
}
.scroll-text {
  color: black;
  font-size: 30px;
}
.crisis {
  margin: auto;
  align-items: center;
  align-content: center;
  white-space: pre-line;

  @media (max-width: 767px) {
    width: 100%;
    font-size: 20px;

    margin-left: 0px;
  }
}
.tof-design {
  background-color: #0065b8;
  width: 60px;
  margin-bottom: 20px;
  border-radius: 50%;
  text-decoration: none;
  display: inline-block;
  padding: 20px;
}
.height-adjust-pic {
  max-height: 850px;
  height: auto !important;
  width: 100% !important;
  object-fit: cover !important;
  /* height: 1080px;*/
  background-size: contain !important;
}


